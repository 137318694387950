.patient-informations-area {
    width: 100%;
    /* max-width: 1280px; */
    padding: 0px 35px;
}

.patient-data-content div p {
    display: flex;
    gap: 6px;
}

.patient-informations-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    gap: 20px;
    background-color: #ffffff;
    box-shadow: 2px 2px 2px rgba(126, 126, 126, 0.5);
    display: flex;
    padding: 20px;
    flex-direction: row;
    overflow: hidden;
}

.patient-more-details:hover {
    color: var(--color-secondary);
    cursor: pointer;
}

.patient-image-area,  .patient-data-area {
    height: 100%;
}

.patient-image-area {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-default-image {
    border-radius: 50%;
}

.patient-data-area {
    display: flex;
    flex-direction: column;
}

.patient-data-area div {
    display: flex;
    flex-direction: column;
}

.title-patient-information {
    font-weight: bold;
}

#modal-more-details {
    display: none;
}

@media (max-width: 810px) {

    /* .modal-more-details {
        display: flex !important;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 9;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0, 0.5);
    } */

    .more-details-content {
        width: 90%;
        z-index: 999;
        position: fixed;
        height: 90%;
        background-color: #ffffff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 20px 10px 20px 10px;
    }

    .close-modal-content {
        width: 100%;
        height: 30px;
        margin-left: -20px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: flex-end;
    }

    .close-modal-content img {
        width: 25px;
        height: 25px;
    }

    .mobile-patient-informations-content {
        width: 100%;
        height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px 10px 20px 10px;
    }

} 

@media (max-width: 400px) {

    .patient-image-area {
        width: auto;
    }
    
    .patient-data-area {
        width: 65%;
    }

    .patient-data-content {
        width: 100%;
        height: 80%;
    }

    .patient-data-content div:nth-child(1) {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .patient-username {
        font-weight: bold;
    }

    .patient-data-content div:nth-child(2),
    .patient-data-content div:nth-child(3),
    .patient-data-content div:nth-child(4) {
        display: none;
    }

    .patient-more-details {
        width: 100%;
        height: 20%;
        display: flex;
        align-items: end;
        justify-content: center;
        padding-right: 10px;
        font-weight: bold;
    }

}

@media (max-width: 810px) {

    .patient-image-area {
        width: auto;
    }
    
    .patient-data-area {
        width: 100%;
        justify-content: end;
        display: flex;
    }

    .patient-data-content {
        width: 100%;
        height: 80%;
    }

    .patient-data-content div:nth-child(1) {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-evenly;
    }

    .patient-username {
        font-weight: bold;
    }

    .patient-data-content div:nth-child(2),
    .patient-data-content div:nth-child(3),
    .patient-data-content div:nth-child(4) {
        display: none;
    }

    .patient-more-details {
        width: 100%;
        height: 20%;
        display: flex;
        align-items: end;
        justify-content: center;
        padding-right: 10px;
        font-weight: bold;
    }

}


@media (max-width: 580px) {
    .patient-informations-content  {
        flex-wrap: wrap;
    }

    .patient-informations-content  div {
        gap: 50px;
    }

    .patient-informations-content  div:nth-child(1) {
        align-items: center;
    }

    .patient-data-content div:nth-child(1) {
        width: auto;
    }

    .patient-more-details {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 811px) {

    .patient-image-area {
        width: auto;
        min-width: 130px;
        max-width: 150px;
    }
    
    .patient-data-area {
        width: inherit;
    }

    .patient-data-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0px 30px;
        flex-direction: row !important;
        gap: 20px;
    }

    .patient-data-content span {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    .patient-data-content div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .patient-data-content div:nth-child(1),
    .patient-data-content div:nth-child(2),
    .patient-data-content div:nth-child(3),
    .patient-data-content div:nth-child(4) {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: top;
        gap: 5px;
    }

    .patient-more-details span {
        display: none;
    }

    #modal-more-details {
        display: none !important;
    }

}