.header-component {
    width: 100%;
    display: flex;
    justify-content: center;
}

.header-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.logo-tools-header-area {
    width: 100%;
    height: 100px;
    padding: 30px;
    display: flex;
    flex-direction: row;
}

.main-logo-header-area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tools-header-area {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    overflow: hidden;
}

.tools-header-area article {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.secondary-logo-header-area {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secondary-logo-header {
    width: 50%;
    height: 100%;
    max-width: 190px;
    min-width: 100px;
}

.search-header-area {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: end;
    justify-content: center;
}

@media (max-width: 400px) {
    
    .main-logo-header {
        width: 70%;
        min-width: 100px;
        min-height: 100px;
        height: 70%;
    }

    .tools-header-area img {
        width: 20px;
        height: 20px;
        margin-left: 30px;
    }

    .tools-header-area img:first-child {
        margin-right: 20px;
    }

}

@media (min-width: 401px) and (max-width: 768px) {
    
    .main-logo-header {
        width: 135px;
    }

    .tools-header-area img {
        width: 20px;
        height: 20px;
        margin-left: 30px;
    }

    .tools-header-area img:first-child {
        margin-right: 20px;
    }

}

@media (min-width: 769px) {
    
    .main-logo-header {
        width: 150px;
    }

    .tools-header-area img {
        width: 20px;
        height: 20px;
        margin-left: 30px;
        cursor: pointer;
    }

    .tools-header-area img:first-child {
        margin-right: 20px;
    }

}